<template>
  <div ref="beltListEl" :class="$style.belt">
    <span :class="$style.beltTitle">{{ title }}</span>

    <app-slider :breakpoints="sliderBreakpoints" :items="items">
      <template v-slot="{ item }">
        <app-link
          :style="`--main-color: ${item.colors.main}; --light-color: ${item.colors.light}`"
          :to="getTagPath(item.genresSlugs)"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'beltItem')"
          :class="$style.beltListItem"
          @click="onItemClick"
        >
          <span :class="$style.beltListItemName">{{ item.name }}</span>
        </app-link>
      </template>
    </app-slider>
  </div>
</template>

<script lang="ts" setup>
import { AnalyticEventNameNew, AnalyticPageName, useMainPageAnalytics } from '@package/sdk/src/analytics';
import type { GenresBeltItem } from '@package/sdk/src/api';
import { useIntersectionObserver } from '@vueuse/core';
import type { RouteLocationRaw } from 'vue-router';

import { AdriverAnalyticEvent, useKmzaDriverEvents } from '@/code/kmza/use-driver-kmza';
import type { SliderBreakpoints } from '@/components/app-slider/AppSlider.vue';
import AppLink from '@/components/ui/AppLink.vue';
import { AppRoute } from '@/platform/router/routes';
import { useCatalogStore } from '@/stores/use-catalog-store';

withDefaults(
  defineProps<{
    items: GenresBeltItem[];
    title?: string;
  }>(),
  {
    title: '',
  },
);

const { $sendAnalyticEvent, $analyticSender } = useNuxtApp();

const mainPageAnalytics = useMainPageAnalytics($analyticSender);
const { injectAdriverScript } = useKmzaDriverEvents();

const catalogStore = useCatalogStore();

const sliderBreakpoints: SliderBreakpoints = {
  mobile: 2,
  smallTablet: 3,
  mediumTablet: 3,
  bigTablet: 5,
  smallDesktop: 5,
  mediumDesktop: 5,
  bigDesktop: 7,
  largeDesktop: 7,
};

const beltListEl = ref<HTMLElement>();

const { stop: stopIntersect } = useIntersectionObserver(beltListEl, ([{ isIntersecting }]) => {
  if (isIntersecting) {
    mainPageAnalytics.onGenresBeltListDisplayed();

    stopIntersect();
  }
});

const onItemClick = async () => {
  catalogStore.selectedSlugsWatcher.pause();
  $sendAnalyticEvent({ event: AnalyticEventNameNew.GotoCatalogPage, page: AnalyticPageName.Main });
  injectAdriverScript(AdriverAnalyticEvent.PickEmotionButton);

  await nextTick();
  catalogStore.selectedSlugsWatcher.resume();
};

const getTagPath = (slugs: string[]): RouteLocationRaw => ({
  name: AppRoute.MovieFilter,
  params: {
    f: `${slugs.join('-')}`,
  },
});

onBeforeUnmount(stopIntersect);
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.belt {
  display: flex;
  flex-direction: column;
  padding: 0 var(--g-spacing-40);
  width: 100%;
}

.beltTitle {
  margin-bottom: 30px;
  padding-left: var(--g-spacing-8);

  @include fonts.WebTitle-2;
}

.beltListItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 140px;
  border-radius: var(--g-border-round-12);
  // добавить вторую переменную после доработок на бэке
  background: linear-gradient(to right bottom, var(--main-color), var(--light-color));
}

.beltListItemName {
  display: -webkit-box;
  padding: 0 var(--g-spacing-10);
  overflow: hidden;
  color: var(--color-text-primary);
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-align: center;

  @include fonts.WebSubtitle-1;
}

@include breakpoints.max-width-800 {
  .belt {
    padding: 0 var(--g-spacing-16);
  }

  .beltTitle {
    padding-left: var(--g-spacing-4);
  }
}
</style>
