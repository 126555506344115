<template>
  <div :class="$style.channels">
    <div :class="$style.channelsTitleBlock">
      <h2 :class="$style.channelsTitle">{{ title }}</h2>
      <span :class="$style.live" :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.TV, 'liveLabel')">
        {{ $t('main.channels.liveLabel') }}
      </span>
    </div>
    <app-slider :breakpoints="sliderBreakpoints" :items="channels">
      <template v-slot="{ item }">
        <tv-channel-card
          :id="item.id"
          :key="item.id"
          :link="{
            name: AppRoute.TVChannel,
            params: { id: item.slug },
          }"
          :img-url="item.currentProgram.background"
          :channel="item"
          :class="$style.channelCard"
          @click="onTvChannelClick(item)"
        >
          <template v-slot:background>
            <app-img
              v-if="item.currentProgram.background"
              :width="$isMobile ? 480 : 640"
              :src="item.currentProgram.background"
              :image-class="$style.background"
              :lazy="true"
              :class="$style.backgroundImage"
            />
          </template>
        </tv-channel-card>
      </template>
    </app-slider>
  </div>
</template>

<script lang="ts" setup>
import { useTvPageAnalytics } from '@package/sdk/src/analytics';
import type { Channel } from '@package/sdk/src/api';

import { ElementTestIdentifierScope, getTestElementIdentifier } from '@/code/e2e-testing/element-test-identifier';
import type { SliderBreakpoints } from '@/components/app-slider/AppSlider.vue';
import TvChannelCard from '@/components/tv-page/TvChannelCard.vue';
import AppImg from '@/components/ui/AppImg.vue';
import { AppRoute } from '@/platform/router/routes';

defineProps<{
  title: string;
  channels: Channel[];
}>();

const { $analyticSender } = useNuxtApp();
const tvPageAnalytics = useTvPageAnalytics($analyticSender);
const { $contentCacheManager } = useNuxtApp();

const onTvChannelClick = (item: Channel) => {
  $contentCacheManager.addToCache(item);

  tvPageAnalytics.onGoToTvChannelPage(item);
};

const sliderBreakpoints: SliderBreakpoints = {
  mobile: 1,
  smallTablet: 2,
  mediumTablet: 3,
  bigTablet: 3,
  smallDesktop: 4,
  mediumDesktop: 5,
  bigDesktop: 5,
  largeDesktop: 5,
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.channels {
  display: flex;
  flex-direction: column;
  padding: 0 var(--g-spacing-40);
  width: 100%;
}

.channelsTitleBlock {
  display: flex;
  align-items: center;
  gap: var(--g-spacing-20);
  margin-bottom: var(--g-spacing-24);
  padding-left: var(--g-spacing-8);
}

.channelsTitle {
  @include fonts.WebTitle-2;
}

.channelCard {
  position: relative;
  height: 100%;
}

.background {
  width: 100%;
  height: 100%;
}

.backgroundImage {
  object-fit: cover;
}

.logo {
  position: absolute;
  bottom: var(--g-spacing-20);
  left: 5px;
  max-height: 26px;
}

.live {
  display: inline-block;
  padding: var(--g-spacing-4) var(--g-spacing-8);
  border-radius: var(--g-border-round-8);
  background: var(--gradient-notheme-red-bottom-to-top);
  color: var(--color-text-primary);

  @include fonts.WebLabel-2;
}

@include breakpoints.max-width-800 {
  .channels {
    padding: 0 var(--g-spacing-16);
  }
  .channelsTitleBlock {
    padding-left: var(--g-spacing-4);
  }
}
</style>
