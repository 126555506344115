<template>
  <div
    :class="$style.progressContainer"
    :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.Main, 'tvProgressBar')"
  >
    <div
      v-if="startTime && endTime"
      :class="$style.progressBar"
      :style="{
        width: `${watchProgressPercentage}%`,
      }"
    />
  </div>
  <app-img v-if="imgUrl" :src="imgUrl" :image-class="$style.image" />
  <div v-else :class="$style.imageStub" />
  <p :class="$style.endTime">{{ timeLeft }}</p>
  <div :class="$style.bottomGradientOverlay" />
</template>

<script setup lang="ts">
import { useWatchingItemHelpers } from '@package/content-utils/src/code/use-watching-item-helpers';

import { ElementTestIdentifierScope, getTestElementIdentifier } from '@/code/e2e-testing/element-test-identifier';
import AppImg from '@/components/ui/AppImg.vue';
const { getProgressPercentage } = useWatchingItemHelpers();
const props = defineProps<{
  startTime: number;
  endTime: number;
  imgUrl?: string;
  timeLeft?: string;
}>();

const watchProgressPercentage = computed(() => getProgressPercentage(props.startTime, props.endTime));
</script>

<style module lang="scss">
@use '@package/ui/src/styles/fonts.scss' as fonts;

.progressContainer {
  position: absolute;
  bottom: var(--g-spacing-16);
  left: var(--g-spacing-16);
  right: var(--g-spacing-16);
  z-index: var(--z-index-progress-bar-tv-program);
  height: 6px;
  border-radius: var(--g-border-round-8);
  background-color: var(--color-bg-button-ghost-80);
}
.progressBar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 6px;
  border-radius: var(--g-border-round-8);
  background-color: var(--color-bg-accent);
}
.imageStub {
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-tertiary);
}

.bottomGradientOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-bottom-gradient-tv-channel-card);
  width: 100%;
  height: 82px;
  background: var(--gradient-notheme-primary-bottom-to-top);
}

.image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.1);
  width: 100%;
  height: 100%;
}

.endTime {
  position: absolute;
  bottom: 30px;
  right: var(--g-spacing-16);
  z-index: var(--z-index-time-tv-channel-card);

  @include fonts.WebBody-3;
}
</style>
