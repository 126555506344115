<template>
  <article :class="$style.watchProgressCard">
    <app-link
      :to="getContentRoute(content, { isPlayerPage: shouldRedirectToPlayerPage(content.accessKind) })"
      :aria-label="$t('ariaLabel.content.continueWatch', { title })"
      :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.Main, 'continueWatchItem')"
    >
      <div :class="$style.watchProgressCardMedia">
        <template v-if="isContentUnavailable">
          <app-img
            :src="imageSrc"
            :alt="contentImageAlt"
            :width="$isMobile ? 350 : 400"
            :image-class="$style.watchProgressCardImage"
          />
          <unavailable-content-cover :availability-message="availabilityMessage" />
        </template>
        <card-timeline-with-poster
          v-if="!isContentUnavailable"
          :end-time="duration"
          :start-time="offset"
          :img-url="imageSrc"
          :time-left="timeLeft"
        />
      </div>

      <p v-if="title" :class="$style.watchProgressCardTitle">
        {{ title }}
      </p>

      <content-info-subtitle :year="content.year" :genres="content.genres" />
    </app-link>

    <app-slot-button
      :class="{
        [$style.deleteButton]: true,
        [$style.deleteButtonMobile]: $isMobile,
      }"
      @click="emit('clear-item')"
    >
      <icon-close
        size="small"
        :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.Common, 'removeButton')"
      />
    </app-slot-button>
  </article>
</template>

<script lang="ts" setup>
import useMediaContentAvailability from '@package/content-utils/src/code/use-media-content-availability';
import * as playerHelpers from '@package/media-player/src/player/helpers';
import { type Episode, type Media } from '@package/sdk/src/api';
import { MediaContentType } from '@package/sdk/src/api';

import { ElementTestIdentifierScope, getTestElementIdentifier } from '@/code/e2e-testing/element-test-identifier';
import useLDJSONMarkup from '@/code/linked-data-json-markup/use-ld-json-markup';
import ContentInfoSubtitle from '@/components/content-page/ContentInfoSubtitle.vue';
import UnavailableContentCover from '@/components/content-page/UnavailableContentCover.vue';
import IconClose from '@/components/icons/IconClose.vue';
import CardTimelineWithPoster from '@/components/shared/CardTimelineWithPoster.vue';
import AppImg from '@/components/ui/AppImg.vue';
import AppLink from '@/components/ui/AppLink.vue';
import useLocale from '@/platform/localization/use-locale';
import { useRouteUtils } from '@/platform/router/use-route-utils';

const props = defineProps<{
  content: Media;
}>();

const emit = defineEmits<{
  (e: 'clear-item'): void;
}>();

const { getContentRoute, shouldRedirectToPlayerPage } = useRouteUtils();
const { getContentTimeUnits } = useLDJSONMarkup();
const { translate } = useLocale();

const { getAvailabilityMessage } = playerHelpers.useContentAvailability();
const { isUnavailable, isAvailableSoon, isAvailabilityOverlayShown } = useMediaContentAvailability();

const contentType = computed(() => props.content.contentType);
const duration = computed(() => props.content.duration);
const imageSrc = computed(() => props.content.preview || props.content.background);

const offset = computed(() => props.content.watchingItem?.offset as number);

const title = computed(() => {
  if (props.content.contentType === MediaContentType.MOVIE) {
    return props.content.title;
  }

  const serialTitle = (props.content as Episode).serialTitle;
  return `${serialTitle}. ${props.content.seasonNumber} сезон ${props.content.number} серия`;
});

const year = computed(() => props.content.year);

const contentImageAlt = computed(() => {
  const titleAndYear = `${title.value} ${year.value}`;

  return (contentType.value === 'episode' ? translate('common.serial') : translate('common.movie')) + titleAndYear;
});

const timeLeft = computed(() => {
  const secondsLeft = duration.value - offset.value;
  const [hours, minutes] = getContentTimeUnits(secondsLeft);

  if (hours === 1) {
    return translate('content.watchingItems.timeLeftWithHour', { hours, minutes });
  }

  if (hours) {
    return translate('content.watchingItems.timeLeftWithHourPlural', { hours, minutes });
  }

  return translate('content.watchingItems.timeLeftMinutes', { minutes });
});

const isContentUnavailableOrAvailableSoon = computed(
  () => isUnavailable(props.content) || isAvailableSoon(props.content),
);

const isContentUnavailable = computed(() =>
  isContentUnavailableOrAvailableSoon.value ? isAvailabilityOverlayShown(props.content) : false,
);
const availabilityMessage = computed(() => getAvailabilityMessage(props.content));
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts' as fonts;
@use '@/assets/aspect-ratio' as aspect-ratio;
@use '@/assets/breakpoints' as breakpoints;

.watchProgressCard {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &:hover {
    .watchProgressCardTitle {
      color: var(--color-text-accent);
    }

    .deleteButton {
      opacity: 1;
    }
  }
}

.deleteButton {
  position: absolute;
  top: var(--g-spacing-16);
  right: var(--g-spacing-16);
  z-index: var(--z-index-carousel-clear);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: var(--g-border-round-12);
  background-color: var(--color-bg-button-ghost-80);
  opacity: 0;
  transition:
    opacity 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: var(--color-state-bg-button-hover);
  }
}

.deleteButtonMobile {
  opacity: 1;
}

.watchProgressCardMedia {
  position: relative;
  display: block;
  width: initial !important;
  border: 1px solid var(--color-stroke-primary);
  border-radius: var(--g-border-round-24);
  background-color: var(--color-bg-secondary);
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover, 80px;
  background-position: top, center;
  object-position: center;

  @include aspect-ratio.ratio(1.7, 1);
}

.watchProgressCardImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.1);
  width: auto;
  height: 100%;
}

.watchProgressCardTitle {
  display: inline-block;
  margin: var(--g-spacing-10) 0 var(--g-spacing-4);
  width: fit-content;
  color: var(--color-text-primary);
  transition: all ease 0.2s;

  @include fonts.WebLabel-1;
}

@include breakpoints.max-width-800 {
  .watchProgressCardMedia {
    background-size: cover, 40px;
  }
}
</style>
