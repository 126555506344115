<template>
  <div>
    <app-skeleton :class="$style.listTitle" />

    <div :class="$style.wrapper">
      <div v-for="item in itemsCount" :key="item" :class="$style.card">
        <app-skeleton :class="$style.image" width="100%" animated />
        <app-skeleton :class="$style.title" width="90%" />
        <app-skeleton :class="$style.description" width="40%" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import AppSkeleton from '@/components/skeleton/AppSkeleton.vue';
import useMobile from '@/platform/layout/use-mobile';

const isMobile = useMobile();

const itemsCount = isMobile ? 3 : 5;
</script>

<style lang="scss" module>
@use '@/assets/aspect-ratio' as aspect-ratio;

.wrapper {
  display: flex;
  margin-bottom: var(--g-spacing-24);
}

.listTitle {
  margin-bottom: var(--g-spacing-24);
  max-width: 50%;
  height: 30px;
  border-radius: var(--g-border-round-10);
}

.card {
  margin-right: var(--g-spacing-10);
  width: 100%;
}

.image {
  margin-bottom: var(--g-spacing-10);
  border-radius: var(--g-border-round-24);

  @include aspect-ratio.ratio(1, 1.4);
}

.title {
  margin-bottom: var(--g-spacing-8);
  height: 26px;
  border-radius: var(--g-border-round-8);
}

.description {
  height: 20px;
  border-radius: var(--g-border-round-8);
}
</style>
