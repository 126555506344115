import type { MainPage, MainPageBlock, Media, OfferV2 } from '@package/sdk/src/api';
import { DisplayType } from '@package/sdk/src/api';
import { isClient } from '@vueuse/core';
import type { AsyncData } from 'nuxt/app';

import { useMainPageContent } from '@/code/content/use-fetch-main-page-content';
import { useOffersApi } from '@/code/offer/use-offers-api';

export interface MainPageData {
  blocks: MainPageBlock[];
  continueWatchItems: Media[];
  promoOffer: OfferV2 | undefined;
}

interface FetchMainPageDataPayload {
  setupMetadata: (result: MainPage) => void;
}

export function useMainPageData() {
  const PAGE_SIZE = 3;
  const currentPage = ref(1);
  const pageCount = ref(20);

  const { fetchBlocks, fetchWatchingItems } = useMainPageContent();
  const offersApi = useOffersApi();

  const getPromoOffer = async (sections: MainPageBlock[]) => {
    let promoOffer: OfferV2 | undefined;

    const promoOfferId = sections.find(({ displayType }) => displayType === DisplayType.OffersList)?.offerId;

    if (promoOfferId) {
      const fetchedOffer = await offersApi.getById(promoOfferId);

      if (fetchedOffer) {
        promoOffer = fetchedOffer;
      }
    }

    return promoOffer;
  };

  const fetchMainPageData = ({ setupMetadata }: FetchMainPageDataPayload) => {
    const {
      status,
      data,
      refresh,
      clear,
      error: err,
    } = useLazyAsyncData(
      async () => {
        let sections: MainPageBlock[] = [];
        let continueWatchItems: Media[] = [];

        if (isClient && data?.value?.continueWatchItems) {
          continueWatchItems = [...data.value.continueWatchItems];
        }

        [sections, continueWatchItems] = await Promise.all([
          fetchBlocks({ page: currentPage.value, perPage: PAGE_SIZE }),
          currentPage.value > 1 ? Promise.resolve(continueWatchItems) : fetchWatchingItems(),
        ]);

        const promoOffer = await getPromoOffer(sections);

        setupMetadata(sections);

        if (isClient && data.value?.blocks?.length && currentPage.value !== 1) {
          const items = [...(data.value?.blocks || []), ...sections] as MainPageBlock[];

          if (sections.length <= 2) {
            pageCount.value = Math.ceil(items.length / PAGE_SIZE);
          }

          return {
            blocks: items,
            continueWatchItems,
            promoOffer: promoOffer || data.value?.promoOffer,
          };
        }

        return {
          blocks: sections,
          continueWatchItems,
          promoOffer,
        };
      },
      { server: true },
    ) as AsyncData<MainPageData, Error>;

    return {
      status,
      data,
      refresh,
      clear,
      err,
    };
  };

  return {
    pageCount,
    currentPage,
    fetchMainPageData,
  };
}
