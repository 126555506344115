import useLogger from '@package/logger/src/use-logger';
import type { MainPage, Media } from '@package/sdk/src/api';

import type { MainPageFetchBlockOptions } from '@/code/main-page/use-main-page-api';
import { useMainPageApi } from '@/code/main-page/use-main-page-api';
import { useRecommendationsApi } from '@/code/recommendations/use-recommendations-api';
import { useWatchingItemsApi } from '@/code/watching-items/use-watching-items-api';
import { CookieName } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import { useSessionStore } from '@/stores/use-session-store';

export function useMainPageContent() {
  const sessionStore = useSessionStore();
  const mainPageApi = useMainPageApi();
  const logger = useLogger('use-main-page-content');

  const { isAuth } = storeToRefs(sessionStore);

  const { fetchContinueWatchItems } = useWatchingItemsApi();
  const { fetchCold, fetchPersonal } = useRecommendationsApi();

  const fetchBlocks = (options?: MainPageFetchBlockOptions): Promise<MainPage> => {
    return mainPageApi
      .fetchBlocks(options)
      .then((response) => {
        const responseCopy = [...response];

        return responseCopy.sort((item, nextItem) => item.position - nextItem.position);
      })
      .catch(() => []);
  };

  const fetchWatchingItems = (): Promise<Media[]> => {
    if (isAuth.value) {
      return fetchContinueWatchItems().catch(() => []);
    }

    return Promise.resolve([]);
  };

  const fetchRecommendations = async (): Promise<Media[]> => {
    if (isAuth.value) {
      return fetchPersonal().catch(() => [] as Media[]);
    }

    // Для авторизованных юзеров, в холодных рекомендациях может вернуться взрослый контент
    // Поскольку в профиле мы не разделяем детский/недетский профиль, то для авторизованных рекомендации не запрашиваем
    if (isAuth.value) {
      return Promise.resolve([]);
    }

    const visitorIdCookie = useAppCookie(CookieName.VisitorId);

    try {
      if (!visitorIdCookie.value) {
        return [];
      }

      const cold = await fetchCold(visitorIdCookie.value);
      return cold;
    } catch (error) {
      logger.error(error);

      return [];
    }
  };

  return {
    fetchBlocks,
    fetchRecommendations,
    fetchWatchingItems,
  };
}
