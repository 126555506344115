<template>
  <div v-if="channel" :class="$style.tvChannelCard">
    <app-link
      :to="link"
      :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'tvChannelCard')"
      :class="$style.tvChannelCardLink"
    >
      <div
        :class="{
          [$style.topBlock]: true,
          [$style.withAccentBorder]: tvStickerOptions.type === 'accent',
          [$style.withRegularBorder]: tvStickerOptions.type === 'regular',
        }"
      >
        <span
          v-if="isStickerVisible"
          :class="{
            [$style.accessSticker]: true,
            [$style.accessStickerAccent]: tvStickerOptions.type === 'accent',
            [$style.accessStickerRegular]: tvStickerOptions.type === 'regular',
          }"
        >
          {{ tvStickerOptions.text }}
        </span>
        <card-timeline-with-poster
          :end-time="duration"
          :start-time="progress"
          :img-url="imgUrl"
          :time-left="getRemainingTimeText(channel?.nextProgram?.startTime || '')"
        />
      </div>

      <div :class="$style.bottomBlock">
        <div :class="$style.program">
          <time
            v-if="channel.currentProgram?.startTime"
            :datetime="channel.currentProgram.startTime"
            :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.Main, 'startTimeTVProgramm')"
            :class="$style.time"
          >
            {{ $formatDateWithCustomFormat(new Date(channel.currentProgram.startTime), 'HH:mm') }}
          </time>
          <p :class="$style.programTitle">
            {{ programTitle }}
          </p>
        </div>
        <div :class="$style.logoContainer">
          <div :class="$style.logoBlock">
            <tv-channel-logo v-if="channel.logoWhite" :src="channel.logoWhite" :class="$style.logoImage" />
            <app-img v-else :src="channel.logo" :image-class="$style.logoImage" />
          </div>
          <p :class="$style.channelTitle">
            {{ channel.title }}
          </p>
        </div>
      </div>
    </app-link>
  </div>
</template>

<script lang="ts" setup>
import type { Channel } from '@package/sdk/src/api';
import type { RouteLocationRaw } from 'vue-router';

import { ElementTestIdentifierScope, getTestElementIdentifier } from '@/code/e2e-testing/element-test-identifier';
import useLDJSONMarkup from '@/code/linked-data-json-markup/use-ld-json-markup';
import getTvChannelStickerOptions from '@/code/tv/get-tv-channel-sticker-options';
import CardTimelineWithPoster from '@/components/shared/CardTimelineWithPoster.vue';
import TvChannelLogo from '@/components/tv-page/TvChannelLogo.vue';
import AppImg from '@/components/ui/AppImg.vue';
import AppLink from '@/components/ui/AppLink.vue';
import useLocale from '@/platform/localization/use-locale';

interface DescriptionParams {
  seasonNumber?: number;
  episodeNumber?: number;
  episodeTitle?: string;
  programTitle?: string;
}

const props = defineProps<{
  channel: Channel;
  link: RouteLocationRaw;
  imgUrl?: string;
}>();

const { translate } = useLocale();
const { getContentTimeUnits } = useLDJSONMarkup();

const getTimelineOptions = () => {
  const now = Date.now();
  const startTime = computed(() => props.channel.currentProgram?.startTime);
  const endTime = computed(() => props.channel.nextProgram?.startTime);

  const start = Date.parse(startTime.value ?? '');
  const end = Date.parse(endTime.value ?? '');

  const duration = end - start;
  const progress = now - start;

  return {
    duration,
    progress,
  };
};

const { duration, progress } = getTimelineOptions();

const tvStickerOptions = computed(() => getTvChannelStickerOptions(props.channel.accessKind));
const isStickerVisible = computed(() => props.channel?.accessKind && tvStickerOptions.value.text);
const programTitle = computed(() => {
  const seasonNumber = props.channel.currentProgram?.seasonNumber;
  const episodeTitle = props.channel.currentProgram?.episodeTitle;
  const currentProgramTitle = props.channel.currentProgram?.title;

  if (seasonNumber || episodeTitle) {
    return serialDescription.value;
  }

  return currentProgramTitle;
});

const createSerialDescription = ({
  seasonNumber,
  episodeNumber,
  episodeTitle,
  programTitle,
}: DescriptionParams): string => {
  // трим временно, пока не сделали на беке https://viju-ru.slack.com/archives/C03M7RN4AFK/p1734019327762699
  const title = programTitle?.trim() || '';

  if (!seasonNumber && !episodeNumber) {
    return `${title}. ${episodeTitle || ''}`;
  }

  if (seasonNumber && episodeNumber) {
    const seasonAndEpisode = translate('content.season.and.episode', { seasonNumber, episodeNumber });
    return `${title}. ${seasonAndEpisode}`;
  }

  return '';
};

const serialDescription = computed(() =>
  createSerialDescription({
    seasonNumber: props.channel.currentProgram?.seasonNumber,
    episodeNumber: props.channel.currentProgram?.episodeNumber,
    episodeTitle: props.channel.currentProgram?.episodeTitle,
    programTitle: props.channel.currentProgram?.title,
  }),
);

const getRemainingTimeText = (endTime: string): string => {
  if (!endTime) {
    return '';
  }

  const now = Date.now();
  const end = Date.parse(endTime);
  const timeRemainingMs = end - now;

  if (timeRemainingMs <= 0) {
    return '';
  }
  const totalSeconds = Math.ceil(timeRemainingMs / 1000);
  const [hours, minutes] = getContentTimeUnits(totalSeconds);

  if (hours === 1) {
    return translate('content.watchingItems.timeLeftWithHour', { hours, minutes });
  }

  if (hours > 1) {
    return translate('content.watchingItems.timeLeftWithHourPlural', { hours, minutes });
  }

  return translate('content.watchingItems.timeLeftMinutes', { minutes });
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

.tvChannelCard {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 182px;
  overflow: hidden;
}

.tvChannelCardLink {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  max-width: 100%;
  height: 100%;

  &:hover {
    .channelTitle {
      color: var(--color-text-accent);
    }
  }
}

.channelTitle {
  transition: color 0.3s ease-in-out;

  @include fonts.WebSubtitle-2;
}

.topBlock {
  position: relative;
  flex-shrink: 0;
  border: 1px solid var(--color-stroke-primary);
  border-radius: var(--g-border-round-24);
  overflow: hidden;
  aspect-ratio: 16/9;
}

.withAccentBorder,
.withRegularBorder {
  border: none;
}

.withAccentBorder::before,
.withRegularBorder::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index-border-tv-channel);
  padding: 1px;
  border-radius: var(--g-border-round-24);
  content: '';
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
}

.withAccentBorder::before {
  background: linear-gradient(to bottom, var(--color-notheme-text-action), var(--color-bg-input));
}

.withRegularBorder::before {
  background: linear-gradient(to bottom, var(--color-notheme-text-action-extra), var(--color-bg-input));
}

.containerTopBlock {
  height: 100%;
  border-radius: var(--g-border-round-24);
}

.accessSticker {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: var(--z-index-access-sticker-tv-program);
  padding: 5px var(--g-spacing-16);
  border-radius: var(--g-border-round-8);
  background-color: var(--color-notheme-dim-black-60);

  @include fonts.WebLabel-3;
}

.accessStickerAccent {
  color: var(--color-notheme-text-action);
}

.accessStickerRegular {
  color: var(--color-notheme-text-action-extra);
}

.endTime {
  position: absolute;
  bottom: 30px;
  right: 16px;
  z-index: var(--z-index-time-tv-channel-card);
}

.bottomBlock {
  display: flex;
  flex-direction: column;
  margin-top: var(--g-spacing-16);
  height: 100%;
}

.program {
  display: flex;
  align-items: center;
  margin-bottom: var(--g-spacing-12);
  max-height: 105px;
  overflow: hidden;
}

.programDescription {
  display: -webkit-box;
  margin-top: var(--g-spacing-4);
  overflow: hidden;
  color: var(--color-text-secondary);
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @include fonts.WebBody-2;
}

.programGenre {
  margin-top: var(--g-spacing-4);
  color: var(--color-text-secondary);

  @include fonts.WebLabel-3;
}

.programTitle {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.3s ease-in-out;

  @include fonts.WebBody-2;
}

.time {
  margin-right: var(--g-spacing-12);
  color: var(--color-text-secondary);

  @include fonts.WebLabel-2;
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: var(--g-spacing-16);
  margin-top: auto;
}

.logoBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  border-radius: var(--g-border-round-8);
  background-color: var(--color-notheme-bg-stream);
}

.logoImage {
  display: flex;
  justify-content: center;
  height: 14px;
}
</style>
