<template>
  <div :class="$style.mainPage">
    <div :class="$style.mainPageContent">
      <article v-if="promoblock" :class="$style.mainBlock">
        <promo-kinoms-slider
          :items="promoblock?.contentMomentsList || []"
          :title="promoblock.title"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'promoBlock')"
          :style="{ order: 1 }"
          @on-slide-left="mainPageAnalytics.onPromoHeaderSliderSwipedLeft()"
          @on-player-ready="onPromoKinomsPlayerReady"
        />
      </article>

      <article v-if="data?.continueWatchItems?.length" :class="$style.mainBlock">
        <continue-watch
          :key="data?.continueWatchItems?.length"
          :items="data?.continueWatchItems"
          :class="{
            [$style.continueWatch]: true,
            [$style.continueWatchAsFirstItem]: !promoblock,
          }"
          :style="{ order: 2 }"
          @on-slide-left="mainPageAnalytics.onContinueWatchItemBlockSwipedLeft()"
        />
      </article>

      <article
        v-for="(block, index) in filteredPageBlocks"
        :key="index"
        :class="$style.mainBlock"
        :style="{ order: block.position }"
      >
        <div
          v-if="block.displayType === DisplayType.ContentMomentList"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'playlist')"
          :class="$style.contentMomentList"
        >
          <h2 :class="$style.momentsListTitle">
            {{ block.title }}
          </h2>

          <kinoms-list
            :use-content-title="true"
            :moments="block?.contentMomentsList || []"
            :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Content, 'kinomsList')"
            @on-kinom-click="(moment, position) => onMomentClick(moment, position, block.playlistId)"
          />
        </div>

        <recommendations-list
          v-if="block.displayType === DisplayType.DsmlRecommendations && recommendations?.length"
          :items="recommendations"
          :class="$style.recommendations"
          :title="$t('page.main.recommendationsTitle')"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'recommendations')"
          @on-slide-left="mainPageAnalytics.onDsmlRecommendationsBlockSwiped('right')"
          @on-slide-right="mainPageAnalytics.onDsmlRecommendationsBlockSwiped('left')"
        />

        <div
          v-if="block.displayType === DisplayType.OffersList && block.offerId"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'promoBanner')"
          :class="$style.mainPromoBanner"
        >
          <promo-offer-card v-if="data?.promoOffer" :promo-offer="data?.promoOffer" :placement-route="AppRoute.Index">
            <div :class="$style.promoOfferButtonsBlock">
              <app-button
                :text="DEFAULT_BTN_NAME"
                :class="$style.promoOfferButton"
                :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Common, 'offerButton')"
                @click="onPromoOfferButtonClick(block.offerId)"
              />
            </div>
          </promo-offer-card>
        </div>

        <belt-list
          v-if="block.displayType === DisplayType.GenresBelt"
          :items="block.beltItems"
          :title="block.title"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'belt')"
        />

        <template v-if="block.displayType === DisplayType.TvChannelsList">
          <tv-channels-list
            v-if="channels?.length"
            :channels="channels"
            :title="block.title"
            :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'channelsList')"
          />
          <moments-list-skeleton v-else :class="$style.channelsSkeleton" />
        </template>
      </article>
    </div>

    <div v-if="status === 'pending'" :class="$style.skeletonContainer">
      <header-slider-skeleton v-if="currentPage === 1" />
      <recommendations-list-skeleton v-if="currentPage === 1" />
      <moments-list-skeleton />
    </div>

    <virtual-pagination
      v-if="status !== 'pending' || !filteredPageBlocks.length"
      :current-page="currentPage"
      :has-loader="true"
      :page-count="pageCount"
      :distance="80"
      @change-current-page="onChangeCurrentPage"
    />

    <scroll-top />
  </div>
</template>

<script lang="ts" setup>
import useLogger from '@package/logger/src/use-logger';
import type { VijuPlayer } from '@package/media-player/src/player/modules/instance/player';
import {
  AnalyticPageName,
  useKinomAnalytics,
  useMainPageAnalytics,
  useSubscribeAnalytics,
} from '@package/sdk/src/analytics';
import type { Channel, MainPage, MainPageBlock, Media } from '@package/sdk/src/api';
import { DisplayType } from '@package/sdk/src/api';
import { getFirstElement } from '@package/sdk/src/core';
import { FeatureToggle } from '@package/unleash/src/feature-toggle/index';
import { watchOnce } from '@vueuse/core';

import { useMainPageData } from '@/code/async-data/use-main-page-data';
import useAuthActions from '@/code/auth/use-auth-actions';
import { useBreadcrumbs } from '@/code/breadcrumbs/use-breadcrumbs';
import { useChannelsApi } from '@/code/channels/use-channels-api';
import { useMainPageContent } from '@/code/content/use-fetch-main-page-content';
import getKmzaPageValue from '@/code/kmza/get-kmza-page-value';
import { AdriverAnalyticEvent, useKmzaDriverEvents } from '@/code/kmza/use-driver-kmza';
import type { CommonMoment } from '@/code/moments/moments';
import { useMyChannelModal } from '@/code/my-channel/use-my-channel-modal';
import { SeoMetaPage, usePageMeta } from '@/code/seo/use-page-meta';
import HeaderSliderSkeleton from '@/components/header-slider/HeaderSliderSkeleton.vue';
import KinomsList from '@/components/kinoms-list/KinomsList.vue';
import BeltList from '@/components/main-page/BeltList.vue';
import ContinueWatch from '@/components/main-page/ContinueWatch.vue';
import PromoKinomsSlider from '@/components/main-page/PromoKinomsSlider.vue';
import RecommendationsList from '@/components/main-page/RecommendationsList.vue';
import PromoOfferCard from '@/components/offers/PromoOfferCard.vue';
import ScrollTop from '@/components/shared/ScrollTop.vue';
import VirtualPagination from '@/components/shared/VirtualPagination.vue';
import MomentsListSkeleton from '@/components/skeleton/MomentsListSkeleton.vue';
import RecommendationsListSkeleton from '@/components/skeleton/RecommendationsListSkeleton.vue';
import TvChannelsList from '@/components/tv-page/TvChannelsList.vue';
import AppButton from '@/components/ui/AppButton.vue';
import useLocale from '@/platform/localization/use-locale';
import { AppRoute } from '@/platform/router/routes';
import { useFeaturesStore } from '@/stores/use-features-store';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useSessionStore } from '@/stores/use-session-store';

definePageMeta({
  layout: 'default',
});

const { setDefaultBreadcrumbs } = useBreadcrumbs();
const { setupSeoMetadata } = usePageMeta();

const logger = useLogger('pages/index.vue');
const { $analyticSender } = useNuxtApp();
const { fetchChannelsRecommendations } = useChannelsApi();
const { fetchRecommendations } = useMainPageContent();

const route = useRoute();
const { translate } = useLocale();

const DEFAULT_BTN_NAME = translate('offerPage.promoBanner.defaultButtonText');

const mainPageAnalytics = useMainPageAnalytics($analyticSender);
const kinomAnalytics = useKinomAnalytics($analyticSender);
const subscribeAnalytics = useSubscribeAnalytics($analyticSender);
const { injectAdriverScript, removeExistingAdriverScript } = useKmzaDriverEvents();

const { navigateToPayment, navigateToAuth } = useAuthActions();

const { checkShouldMyChannelModalOpen, openMyChannelModal } = useMyChannelModal();

const { getStatus } = useFeaturesStore();
const { isAuth } = storeToRefs(useSessionStore());
const layoutStore = useLayoutStore();

setDefaultBreadcrumbs();

const setupMetadata = (result: MainPage) => {
  const firstContentRow = result[0]?.contentMomentsList;

  if (!firstContentRow) {
    return;
  }

  const firstMoment = getFirstElement(firstContentRow);

  setupSeoMetadata({ page: SeoMetaPage.Home, args: [firstMoment] });
};

const { fetchMainPageData, currentPage, pageCount } = useMainPageData();
const {
  status,
  data,
  err,
  refresh: refreshMainPageData,
  clear: clearMainPageData,
} = fetchMainPageData({ setupMetadata });

if (err.value) {
  console.error(err.value);
}

const recommendations = ref<Media[]>();
const channels = ref<Channel[]>([]);

const promoKinomsPlayer = ref<VijuPlayer>();

const filteredPageBlocks = computed<MainPageBlock[]>(() => {
  if (!data.value?.blocks) {
    return [];
  }

  return data.value.blocks.filter(
    ({ displayType }: MainPageBlock) =>
      displayType !== DisplayType.MomentList && displayType !== DisplayType.PromoBlock,
  );
});

const promoblock = computed(() => {
  if (!data.value?.blocks) {
    return undefined;
  }

  return data.value.blocks.find(({ displayType }: MainPageBlock) => displayType === DisplayType.PromoBlock);
});

const onPromoOfferButtonClick = (offerId: string) => {
  injectAdriverScript(AdriverAnalyticEvent.PromoTryButton);
  subscribeAnalytics.onGotoOfferPage({ page: AnalyticPageName.Main });

  return isAuth.value ? navigateToPayment(offerId) : navigateToAuth({ offerId });
};

const fetchChannels = () => {
  if (!getStatus({ name: FeatureToggle.TvChannelOnMain })) {
    return Promise.resolve([]);
  }

  return fetchChannelsRecommendations();
};

const onChangeCurrentPage = async (newCurrentPage: number) => {
  currentPage.value = newCurrentPage;
  await refreshMainPageData();
};

const onPromoKinomsPlayerReady = (plr: VijuPlayer) => {
  promoKinomsPlayer.value = plr;
};

const onMomentClick = (moment: CommonMoment, position: number, playlistId: string) => {
  const analyticsPayload = {
    page: getKmzaPageValue(route.name as AppRoute),
    moment,
    position,
  };

  kinomAnalytics.onGotoKinomPage(analyticsPayload);
  kinomAnalytics.onClickKinomSelect(analyticsPayload);

  openMyChannelModal({
    momentId: moment.id,
    playlistId,
  });
};

const doCheckShouldMyChannelModalOpen = async () => {
  try {
    await checkShouldMyChannelModalOpen();
  } catch (error) {
    logger.error(error);
  }
};

// при редиректе без ssr в маунтеде еще не подгружены плейлисты, поэтому делаем вотч
watchOnce(
  () => data.value,
  async () => {
    if (isAuth.value) {
      await doCheckShouldMyChannelModalOpen();
    }
  },
);

onMounted(async () => {
  injectAdriverScript(AdriverAnalyticEvent.RecommendationsList);
  [recommendations.value, channels.value] = await Promise.all([fetchRecommendations(), fetchChannels()]);

  if (!isAuth.value) {
    await doCheckShouldMyChannelModalOpen();
  }
});

onBeforeUnmount(() => {
  clearMainPageData();

  removeExistingAdriverScript(AdriverAnalyticEvent.RecommendationsList);
  layoutStore.clearScrollPosition(route.name as AppRoute);
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.mainPage {
  margin-bottom: var(--g-spacing-40);
}

.mainPageContent {
  display: flex;
  flex-direction: column;
}

.recommendations {
  padding: 0 var(--g-spacing-40) 0;
}

.channelsSkeleton {
  padding: 0 var(--g-spacing-48);
}

.momentsListTitle {
  margin-bottom: var(--g-spacing-24);
  padding: var(--g-spacing-8);

  @include fonts.WebTitle-2;
}

.continueWatch,
.contentMomentList {
  padding: 0 var(--g-spacing-40);
}

.mainBlock {
  display: flex;
  flex-direction: column;
}

.mainBlock:not(:last-child) {
  margin-bottom: var(--g-spacing-80);
}

.mainBlock:first-child {
  margin-top: var(--g-spacing-24);
}

.mainPromoBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 var(--g-spacing-48);
}

.promoOfferButtonsBlock {
  display: flex;
  align-items: center;
  margin-top: var(--g-spacing-24);
}

.promoOfferButton {
  margin-right: var(--g-spacing-32);
  padding: var(--g-spacing-12) var(--g-spacing-40);
  border-radius: var(--g-border-round-12);
}

.continueWatch {
  margin: 0;
}

.continueWatchAsFirstItem {
  margin: 0;
  margin-bottom: var(--g-spacing-80);
}

.skeletonContainer {
  padding: 0 var(--g-spacing-48);
}

@include breakpoints.max-width-800 {
  .mainBlock:not(:last-child) {
    margin-bottom: var(--g-spacing-32);
  }

  .mainBlock:first-child {
    margin-top: var(--g-spacing-16);
  }

  .recommendations {
    padding: 0 var(--g-spacing-16);
  }

  .momentsListTitle {
    padding: var(--g-spacing-4);
    max-width: 100%;
  }
  .continueWatch,
  .contentMomentList {
    padding: 0 var(--g-spacing-16);
  }

  .mainPromoBanner {
    padding: 0 var(--g-spacing-20);
  }

  .continueWatch {
    margin-top: var(--g-spacing-40) 0 0 0;
  }

  .continueWatchAsFirstItem {
    margin: 0;
    margin-bottom: var(--g-spacing-40);
  }

  .skeletonContainer {
    margin-top: var(--g-spacing-40);
    padding: 0 var(--g-spacing-16);
  }
}

@include breakpoints.max-width-480 {
  .promoOfferButtonsBlock {
    flex-direction: column;
    width: 100%;
  }

  .promoOfferButton {
    margin: 0;
    margin-bottom: var(--g-spacing-16);
    width: 100%;
  }
}
</style>
